var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column is-narrow-mobile" }, [
    _c(
      "div",
      { staticClass: "box" },
      [
        _c("h1", { staticClass: "is-size-5" }, [_vm._t("title")], 2),
        _c("hr"),
        _vm._t("message"),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [_vm._t("content")], 2)
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }