<template>
  <div class="column is-narrow-mobile">
    <div class="box">
      <h1 class="is-size-5">
        <slot name="title"></slot>
      </h1>
      <hr>
      <slot name="message">
      </slot>
      <div class="columns">
        <div class="column">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commonTemplate',
  data () {
    return {

    }
  }
}
</script>

<style scoped>

</style>
